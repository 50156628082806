@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=vietnamese);
@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto%20Condensed:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap);
html:focus-within {
  scroll-behavior: smooth; }

body,
html {
  margin: 0;
  font-size: 14px;
  font-family: 'Roboto';
  background-color: #000000; }

body {
  margin: 0 auto; }
  body.projectw-theme {
    font-family: 'Neue Haas Grotesk Display Pro'; }
  body p {
    font-family: inherit;
    font-size: inherit; }
  body .swal2-popup .swal2-styled.swal2-confirm {
    padding: 11px 48px; }
  body .swal2-title {
    color: #000 !important; }
  body .swal2-html-container {
    margin: 0; }
  @media (max-width: 991px) {
    body .swal2-title {
      right: 5px; } }

#root {
  background-color: transparent; }

.v-livePage {
  overflow: auto !important; }
  .v-livePage footer .v-footer {
    display: none; }

.slick-slider .slick-track {
  margin-left: 0; }

.clear {
  clear: both; }

.texttruncate {
  font-size: 16px;
  word-wrap: break-word; }

.container {
  width: 100%;
  margin: 0 auto;
  padding-right: 0px;
  padding-left: 0px;
  /* margin-bottom: 40px; */ }

.rootContainer {
  font-family: 'Open Sans';
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  background: #fff;
  min-height: 100vh; }
  .rootContainer > div:nth-child(2):not(footer) {
    flex-grow: 1; }

.App.projectw {
  margin: 0 auto; }
  .App.projectw.white-background .rootContainer,
  .App.projectw.white-background .headerW--Container {
    background-color: #fff; }
    .App.projectw.white-background .rootContainer .caret,
    .App.projectw.white-background .headerW--Container .caret {
      display: none; }
    .App.projectw.white-background .rootContainer .switchLanguageW .language--name,
    .App.projectw.white-background .headerW--Container .switchLanguageW .language--name {
      color: #000; }
  .App.projectw:not(.white-background) .headerW--Container *:not(.v-nav--profile-name) {
    color: #fff;
    fill: #fff; }
    .App.projectw:not(.white-background) .headerW--Container *:not(.v-nav--profile-name) .accountLogin path {
      fill: #c5c5c8;
      stroke: #c5c5c8; }
    .App.projectw:not(.white-background) .headerW--Container *:not(.v-nav--profile-name) .caret {
      display: none; }
  .App.projectw:not(.white-background) .headerW--Container .v-logo img {
    filter: invert(1); }
  .App.projectw:not(.white-background) .headerW--Container .activeW {
    background-color: #000;
    width: 100%; }
  .App.projectw:not(.white-background) .headerW--Container .switchLanguageW .btn-default {
    background-color: #000000; }
  .App.projectw:not(.white-background) .headerW--Container .switchLanguageW-Toggle .caret {
    display: none; }
  .App.projectw:not(.white-background) .headerW--Container .switchLanguageW-Toggle .IconsArrow path {
    fill: #000;
    stroke: #fff; }
  .App.projectw:not(.white-background) .headerW--Container .switchLanguageW-MenuItem {
    border: 2px solid #fff; }
    .App.projectw:not(.white-background) .headerW--Container .switchLanguageW-MenuItem-parent:hover span {
      color: #fff; }
    .App.projectw:not(.white-background) .headerW--Container .switchLanguageW-MenuItem-parent span {
      color: #000; }

.language--name {
  margin-left: 30px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase; }

ol,
ul {
  list-style: none; }

.v-fa {
  width: 22px;
  min-height: 22px;
  background-size: cover;
  background-color: #fff;
  background-position: center; }
  .v-fa-card {
    background-image: url(/static/media/fa-card.58d51e2a.svg); }
  .v-fa-card-circle {
    min-height: 25px; }
  .v-fa-film {
    background-image: url(/static/media/film.1cd08c72.svg);
    background-color: transparent;
    width: 24px; }
  .v-fa-paypal {
    background-image: url(/static/media/fa-paypal.a57d8ea4.svg); }

:focus {
  outline: none; }

.modal .modal-dialog {
  pointer-events: none; }
  .modal .modal-dialog .modal-content {
    pointer-events: auto; }

.fa-key {
  transform: rotateZ(45deg) rotateX(180deg); }

::-webkit-scrollbar {
  width: 10px; }

::-webkit-scrollbar-thumb {
  background-color: #676767;
  border-radius: 10px; }

input::-ms-reveal,
input::-ms-clear {
  display: none; }

input[type='date']::-webkit-clear-button {
  display: none; }

.datetime-reset-button {
  display: none !important; }

.v-categoryPage .v-sub-category {
  position: relative;
  background: #333333;
  color: #aeafb0; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  background: #fff;
  color: #fff;
  transition-delay: 9999s;
  transition-property: background-color, color;
  -webkit-transition-delay: 9999s;
  -webkit-transition-property: background-color, color; }

.isDesktop {
  display: none !important; }

.error-data {
  margin-top: 10px;
  font-size: 14px;
  color: #ed5858;
  text-align: left; }

.project-W-error {
  font-size: 12px !important;
  font-weight: 500;
  margin-top: 0 !important;
  padding-left: 8px;
  color: #e92121 !important; }

.version.projectw {
  font-size: 12px;
  color: #707070;
  font-family: 'Neue Haas Grotesk Display Pro'; }

@media (min-width: 768px) {
  body,
  html {
    font-size: 14px; } }

@media (min-width: 992px) {
  body,
  html {
    font-size: 16px; }
  .isDesktop {
    display: unset !important; }
  .isMobile {
    display: none !important; } }

@media (min-width: 1024px) {
  .v-livePage {
    overflow: hidden !important; } }

@media (min-width: 1180px) {
  body.projectw-theme #root,
  body.projectw-theme .headerW--Container,
  body.projectw-theme .wrap-video--zoom {
    max-width: 80vw;
    margin: 0 auto; } }

.vjs-player-fullmode .float-ads {
  display: none; }

